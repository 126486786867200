import { TagTypes } from 'shared/api/types';

import { ITimeTrackerTrackable } from 'domain/timeTracker/trackable/types';

import { apiRoutes } from 'routes';

import { apiContainer } from 'store';

import { ISortParameter } from 'types/runsack';

import { ReportTrackedTimeEntriesCopyingType } from './enums';
import { IGetReportTrackedTimeEntriesUserListItem } from './types';

interface IGetReportTrackedTimeEntriesUsersListResponse {
  users: Array<IGetReportTrackedTimeEntriesUserListItem>;
}

export interface IReportTrackedTimeEntriesUsersListParameters {
  listType: ReportTrackedTimeEntriesCopyingType;
  hoursEq?: number | null;
  hoursNoteq?: number | null;
  hoursLteq?: number | null;
  hoursLt?: number | null;
  hoursGteq?: number | null;
  hoursGt?: number | null;
  descriptionCont?: string;
  resourceIdsIn?: Array<number>;
  trackables?: Array<ITimeTrackerTrackable>;
  taskIdsIn?: Array<number>;
  s?: ISortParameter;
  afterDate?: string;
  beforeDate?: string;
}

export const reportTrackedTimeEntryUsersListApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReportTrackedTimeEntriesUsersList: builder.query<
      IGetReportTrackedTimeEntriesUsersListResponse,
      IReportTrackedTimeEntriesUsersListParameters
    >({
      query: parameters => {
        return {
          url: apiRoutes.usersListApiV1ReportsTrackedTimeEntriesGroupedByUsersPath(),
          method: 'GET',
          params: {
            ...parameters,
          },
        };
      },
      providesTags: [TagTypes.ReportTrackedTimeEntryUsersList],
    }),
  }),
  overrideExisting: false,
});

export const { useGetReportTrackedTimeEntriesUsersListQuery } = reportTrackedTimeEntryUsersListApi;
